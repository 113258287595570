import { render, staticRenderFns } from "./PasswordForm.vue?vue&type=template&id=197ae927&scoped=true&"
import script from "./PasswordForm.vue?vue&type=script&lang=js&"
export * from "./PasswordForm.vue?vue&type=script&lang=js&"
import style0 from "./PasswordForm.vue?vue&type=style&index=0&id=197ae927&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "197ae927",
  null
  
)

export default component.exports